/**
 * @订单部分
 * 2022-08-12 kadiam
*/
import bossApi from '../utils/bossApi'
import bossXypq from '../utils/bossXypq'
import bossCloud from '../utils/bossCloud'

import { getJSSDKSign, orderPay, cloudroom_orderPay } from '@/api/public'
import router from './../router'
const wx = require('jweixin-module')
// import { Toast } from 'vant'
// 商品搜索 热门搜索
export function mallOrder_confirmOrderUnion(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/confirmOrderUnion', method: 'POST', data })
}
// 获取默认地址
export function findAddress(data) {
  return bossXypq({ url: 'hx.baoxian/userReceiptAddress/findAddress', method: 'POST', data })
}
// 商城订单-运费计算
export function confirmExpressPrice(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/confirmExpressPrice', method: 'POST', data })
}
// 账期计算
export function quoteStaging_calcQuoteStagingResDto(data) {
  return bossXypq({ url: 'hx.baoxian/quoteStaging/calcQuoteStagingResDto', method: 'POST', data })
}
// 确认账期
export function quoteStaging_confirmQuoteStagingResDto(data) {
  return bossXypq({ url: 'hx.baoxian/quoteStaging/confirmQuoteStagingResDto', method: 'POST', data })
}
// 用户订单列表
// export function mallOrder_buyer_list(data) {
//   return bossXypq({ url: 'hx.baoxian/mallOrder/buyer/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize, method: 'GET' })
// }
export function mallOrder_buyer_list(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/order/page', method: 'POST', data })
}

// 用户订单详情
// export function mallOrder_buyer_detail(data) {
//   return bossXypq({ url: 'hx.baoxian/mallOrder/buyer/detail?orderId=' + data, method: 'GET' })
// }
// 修改订单收货地址
// export function mallOrder_buyer_updateAdress(data) {
//   return bossXypq({ url: 'hx.baoxian/mallOrder/buyer/updateAdress', method: 'POST', data })
// }
export function mallOrder_buyer_updateAdress(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/order/updateAddress?orderId=' + data.orderId + '&userReceiptAddressId=' + data.userReceiptAddressId, method: 'GET' })
}
// 订单使用的优惠活动
export function promotionActivity_usedByOrder(data) {
  return bossXypq({ url: 'hx.baoxian/promotionActivity/usedByOrder?orderId=' + data, method: 'GET' })
}
// 提交订单
export function submitOrderUnion(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/submitOrderUnion', method: 'POST', data })
}

// 二次提交订单
export function resubmitOrderUnion(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/outOrder/replace?orderId=' + data, method: 'GET' })
}
function WXPayStudy(order, info, success, fail) {
  const current_url = location.href.split('#')[0]
  console.log('current_url', current_url)
  getJSSDKSign(current_url).then(sign => {
    const payinfo = sign.data
    console.log('wx.config', info)
    payinfo['jsApiList'] = ['chooseWXPay']
    wx.config(payinfo)
    console.log('wx.config', payinfo)

    wx.ready(function () {
      console.log('wx.config--002')
      wx.chooseWXPay({
        timestamp: info.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: info.nonceStr, // 支付签名随机串，不长于 32 位
        package: info.package || info.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: info.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: info.paySign, // 支付签名
        success: function (res) {
          // 支付成功后的回调函数
          console.log('wx.chooseWXPay success', res)
          router.push('/order/payResult?outOrder=' + order.outOrder + '&status=0' + '&orderId=' + order.orderId)
          success(res)
        },
        cancel: function (err) {
          var status = (order.type === 1 ? '1' : '2')
          console.log('wx.chooseWXPay cancel', order)
          router.push('/order/payResult?outOrder=' + order.outOrder + '&status=' + status + '&orderId=' + order.orderId)
          fail(err)
        },
        fail: function (err) {
          var status = (order.type === 1 ? '1' : '2')
          console.log('wx.chooseWXPay fail', order)
          router.push('/order/payResult?outOrder=' + order.outOrder + '&status=' + status + '&orderId=' + order.orderId)
          fail(err)
        }
      })
    })
  })
}
// 支付 商城
export function payStudy(item, success, fail) {
  orderPay({ outOrder: item.outOrder }).then(res => {
    console.log('orderPay', res.data)
    var result = res.data
    WXPayStudy(item, result, success, fail)
  })
}
// 支付 yunke
export function payStudy_class(item, success, fail) {
  cloudroom_orderPay(item).then(res => {
    console.log('orderPay', res.data)
    // if (parseInt(res.data.flag) === 0) {
    //   router.push('/studyClass/studyDetail?id=' + item.orderId)
    // } else {
    var result = JSON.parse(res.data.biz_content)
    WXPayStudy(item, result.payData, success, fail)
    // }
  })
}

// 退款原因
export function refund_reason(data) {
  return bossApi({ url: 'front/refund/reason', method: 'GET' })
}
// 订单商品退款明细
export function progressingProductRefund(data) {
  return bossApi({ url: 'front/refund/progressingProductRefund?orderId=' + data, method: 'GET' })
}
// 订单退款列表
export function refund_refundlist(data) {
  return bossXypq({ url: 'hx.baoxian/refund/list', method: 'POST', data })
}
// 订单退款详情
export function refund_detail(data) {
  return bossApi({ url: 'front/refund/detail?refundId=' + data, method: 'GET' })
}
// 新增退款前信息确认
export function refund_addBefore(data) {
  return bossApi({ url: 'front/refund/addBefore', method: 'POST', data })
}
// // 发起退货退款
// export function refund_add(data) {
//   return bossApi({ url: 'front/refund/add', method: 'POST', data })
// }
// 发起退货退款
export function refund_add(data) {
  return bossApi({ url: 'front/refund/addV2', method: 'POST', data })
}
// 物流信息或更新原因
export function refund_udpate(data) {
  return bossApi({ url: 'front/refund/udpate', method: 'POST', data })
}
// 退款列表
export function refund_list(data) {
  return bossApi({ url: 'front/refund/list?orderId=' + data, method: 'GET' })
}
// 取消申请退款
export function refund_cancelRefund(data) {
  return bossApi({ url: 'front/refund/cancelRefund?refundId=' + data, method: 'GET' })
}
// 物流详情
export function mallOrder_express(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/express?orderId=' + data, method: 'GET' })
}
export function orderExpressList(data) {
  return bossCloud({ url: 'mall/api/mallOrder/orderExpressList?orderId=' + data, method: 'GET' })
}
// 物流跟着
// export function selectExpressNo(data) {
//   return bossApi({ url: 'storeOrder/selectExpressNo?expressNo=' + data.expressNo + '&expressCode=' + data.expressCode, method: 'GET' })
// }
export function selectExpressNo(data) {
  return bossCloud({ url: 'admin/api/express/getCacheExpressInfo', method: 'POST', data })
}
// 商城订单-买家取消订单
export function order_delete(data) {
  // return bossXypq({ url: 'hx.baoxian/mallOrder/buyer/delete?orderId=' + data.orderId + '&type=' + data.type, method: 'GET' })
  return bossCloud({ url: 'mall/api/mallOrder/delete', method: 'POST', data })
}
// 推客货单-货单查看权限
export function viewAuth(data) {
  return bossXypq({ url: 'hx.baoxian/saleOrder/viewAuth', method: 'GET' })
}
// 货单详情
export function getShoppinglistGoodsDetail(data) {
  return bossXypq({ url: 'hx.baoxian/shoppinglist/getShoppinglistGoodsDetail?shoppinglistId=' + data, method: 'GET' })
}
// 货单详情 --推客
export function saleOrder_detail(data) {
  return bossXypq({ url: 'hx.baoxian/saleOrder/detail?saleOrderNo=' + data, method: 'GET' })
}
// 货单商品
export function shopping_getShoppinglist(data) {
  return bossXypq({ url: 'hx.baoxian/shoppinglist/shopping/getShoppinglist', method: 'POST', data })
}
// 支付成功 回调信息获取（招行）
export function getDetailByid(data) {
  return bossCloud({ url: 'admin/payApi/getDetailByid', method: 'POST', data })
}
// 招行-前端预支付成功回调
export function preCallback(data) {
  return bossCloud({ url: 'admin/payApi/preCallback', method: 'POST', data })
}
// 再来一单
// export function cartAnotherOrder(data) {
//   return bossXypq({ url: 'hx.baoxian/shoppingCart/cartAnotherOrder', method: 'POST', data })
// }
// 完成订单
export function updateComplete(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/updateComplete?orderId=' + data, method: 'GET' })
}
// 提交发票申请
export function submitInvoices(data) {
  return bossXypq({ url: 'hx.baoxian/invoices/submitInvoices', method: 'POST', data })
}
// 发票详情
export function invoicesDetail(data) {
  return bossXypq({ url: 'hx.baoxian/invoices/invoicesDetail?outOrder=' + data, method: 'get' })
}

/**
 * @订单相关接口 2.0 王涛
 * 2024-11-13 kadiam
*/
// 再来一单
export function cartAnotherOrder(data) {
  return bossCloud({ url: 'mall/api/shoppingCart/anotherOrder', method: 'POST', data })
}
// 用户订单详情
export function mallOrder_buyer_detail(data) {
  return bossCloud({ url: 'mall/api/mallOrder/detail?orderId=' + data, method: 'GET' })
}
